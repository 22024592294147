$('.product.actions i').on('click', function(){
    $('#mainModal').modal('show') 
    var id = $(this).data('id');
    var action = $(this).data('action');
    axios.post(route('product.'+action, id))
        .then(function (response) {
            $('.modal-ajax-content').html(response.data);
            $('.drop-select').select2();
        })
        .catch(function (error) {
            console.log(error);
        });
});

$('.productEdit #modalSubmit').on('click', function(e) {
    e.preventDefault();
    let formData = $('.productEdit form').serialize();
    axios.patch(route('product.update'), formData)
        .then(function (response) {
            $('#mainModal').modal('hide');
            if ($('.wrapper').find('#alert').length === 0) {
                $('.wrapper').append('<div id="alert" class="alert alert-success">Artikl uspješno ažuriran!</div>');
            }
            else {
                $('#alert').show();
            }
            $('#alert').delay(3000).hide(300);
        })
        .catch(function (error) {
            console.log(error);
        });
});