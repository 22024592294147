import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
//import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import hrLocale from '@fullcalendar/core/locales/hr';
import enLocale from '@fullcalendar/core/locales/en-gb';

window.calendar = function(sellectId, events) {

    var calendarEl = document.getElementById(sellectId);

    let bubble = $( "<div class='calendar-bubble-wrapper'></div>" );

    return new Calendar(calendarEl, {
        locales: [ hrLocale, enLocale ],
        locale: 'hr', // the initial locale. of not specified, uses the first one
        plugins: [ dayGridPlugin, timeGridPlugin, listPlugin ],
        header: {
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,listWeek'
        },
        defaultDate: Date.now(),
        navLinks: true, // can click day/week names to navigate views
        editable: true,
        eventLimit: true, // allow "more" link when too many events
        events: events,
        eventClick: function(info) {
            // console.log('Event: ' + info.event.title);
            // info.el.style.borderColor = 'red'; // change the border color just for fun
            // console.log('Coordinates: ' + info.jsEvent.pageX + ',' + info.jsEvent.pageY);
            // console.log('View: ' + info.view.type);

            // console.log(info);
            location.href = 'http://ofir.jet-rwe/offer/edit/' + info.event.extendedProps.offerId;
        },
        eventMouseEnter: function(info) {
            if (bubble) {
                let bubbleInner = $('<div class="calendar-bubble-inner"></div>');
                $(info.el).append(bubble.html('').append(bubbleInner));
                $.each(info.event.extendedProps, function ( index, value ) {
                    bubbleInner.append( '<p>'+value+'</p>' );
                });
            }
        },
        eventMouseLeave: function(info) {
            if (bubble) $(info.el).find(bubble).html('').remove();
        }
    }).render();
};