/**
 * Find unique element in array
 */
window.unique = function(array){
	return array.filter(function(el, index, arr) {
		return index === arr.indexOf(el);
	});
};

/**
 * Set a delay on an object function
 */
window.delay = function(fn, ms) {
	let timer = 0;
	return function(...args) {
		clearTimeout(timer);
		timer = setTimeout(fn.bind(this, ...args), ms || 0);
	};
};

/**
 * Make a dropdown selection from a data array
 */
window.selectDropdown = function(select, data, varId = "id", varName = "name") {
	var wrapper = select.parent();
	var isInput = false;
	if (select.attr('type') === "text" || select.attr('type') === "hidden") isInput = true;

	if( ! select.hasClass('dropdown-select') ) {
		wrapper.append('<div class="dropdown-results"></div>');
		select.addClass('dropdown-select');
	}

	var result = wrapper.find('.dropdown-results');
	result.html('');
	for (let index = 0; index < data.length; index++) {
		result.append( '<p class="select-item" data-value="'+data[index][varId]+'">'+data[index][varName]+'</p>' );
	}
	result.show();
	$('.select-item').on('click', function(){
		if (isInput) select.val($(this).data('value'));
		else {
			select.trigger('changeSelectData', [{id: $(this).data('value'), name: $(this).html()}]); 
		}
		result.hide();
	});

	if (isInput) {
		select.bind("focusout", function(){
				result.fadeOut(300);
			}).bind("focusin", function(){
				result.show();
			});
	}
	else {
		$("body").click(function(e) {
			var clickedOn = $(e.target);
			if (clickedOn.closest(".dropdown-results").length) {
				//
			} else {
				result.fadeOut(300);
			}
		});
	}
};

/**
 * Format a JS object to an array
 */
window.format = function(object) {
    var items = {};
    var i = 0;
    for (var index in object.items) {
        items[i] = object.items[index];
        i++;
    }
    object.items = items;
};

/**
 * Create a custom styled checkbox input
 */
window.iCheck = function (item) {
	var cbox = $(item);
	var clabel = cbox.siblings('label');
	var checked = '';
	if (cbox.is(':checked') || cbox.val() === 1) checked = ' checked';
	cbox.hide();
	cbox.wrap('<div class="icheck"></div>').after('<span class="checkbox'+checked+'"></span>');
	cbox.parent().prepend(clabel);

	cbox.siblings('.checkbox').click(function(e){
		e.preventDefault();
		cbox.click();
		$(this).toggleClass('checked');
		if (cbox.is(':checked')) cbox.val(1);
		else cbox.val(0);
	});
};

/**
 * Add GET parameters to curent URL
 */
window.insertParam = function (key, value) {
    key = encodeURI(key); value = encodeURI(value);
    var kvp = document.location.search.substr(1).split('&');
    var i=kvp.length; var x; while(i--) 
    {
        x = kvp[i].split('=');
        if (x[0] === key)
        {
            x[1] = value;
            kvp[i] = x.join('=');
            break;
        }
    }
    if(i<0) {kvp[kvp.length] = [key,value].join('=');}
    //this will reload the page, it's likely better to store this until finished
    document.location.search = kvp.join('&'); 
};

window.session = function (key, value, method = 'put') {
    axios.post(route('session', {key, value, method}))
        .then(function (response) {
        })
        .catch(function (error) {
            console.log(error);
        });
};

/**
 * Generate a password string
 */
window.passGen = function(length = 10, letters = true, numbers = true, signs = true) {
	// passwords can be created only from these characters
	var possible = (letters ? 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ' : '') + (numbers ? '0123456789' : '') + (signs ? '!%&*$#@' : '');
	// set the initial value of text variable to an empty string each time the .editors button.addRow is clicked
	var rand = "";
	// generate a random password
	for (var i=0; i < length; i++) {
		rand += possible.charAt(Math.floor(Math.random() * possible.length));
	}
	return rand;
};

/**
 * 
 */
(function($) {
    $.fn.drags = function(opt) {

        opt = $.extend({
            handle: "",
            cursor: "move",
            draggableClass: "draggable",
            activeHandleClass: "active-handle",
            resize: ".rezise"
        }, opt);

        var $selected = null;
        var $elements = (opt.handle === "") ? this : this.find(opt.handle);

        $elements.css('cursor', opt.cursor).on("mousedown", function(e) {
            if(opt.handle === "") {
                $selected = $(this);
                $selected.addClass(opt.draggableClass);
            } else {
                $selected = $(this).parent();
                $selected.addClass(opt.draggableClass).find(opt.handle).addClass(opt.activeHandleClass);
            }
            var drg_h = $selected.outerHeight(),
                drg_w = $selected.outerWidth(),
                pos_y = $selected.offset().top + drg_h - e.pageY,
                pos_x = $selected.offset().left + drg_w - e.pageX;
            $(document).on("mousemove", function(e) {
                $selected.offset({
                    top: e.pageY + pos_y - drg_h,
                    left: e.pageX + pos_x - drg_w
                });
            }).on("mouseup", function() {
                $(this).off("mousemove"); // Unbind events from document
                if ($selected !== null) {
                    $selected.removeClass(opt.draggableClass);
                    $selected = null;
                }
            });
            e.preventDefault(); // disable selection
        }).on("mouseup", function() {
            if(opt.handle === "") {
                $selected.removeClass(opt.draggableClass);
            } else {
                $selected.removeClass(opt.draggableClass)
                    .find(opt.handle).removeClass(opt.activeHandleClass);
            }
            $selected = null;
        });

        return this;

    };
})(jQuery);

$(".draggable-wrapper").drags({handle:".draggable-header"});

$(".draggable-wrapper").on('mousedown', function(){
    $(".draggable-wrapper").css('z-index', '9999');
    $(this).css('z-index', '10000');
});

$(".draggable-wrapper .btn-box-tool").on("click", function(e) {
    e.preventDefault();
    if ($(this).data('widget') === 'resize') {
        $(this).parent().parent().parent().toggleClass('fullscreen');
    }
    if ($(this).data('widget') === 'collapse') {
        //$(this).parent().parent().parent().toggleClass('');
    }
    if ($(this).data('widget') === 'close') {
        $(this).parent().parent().parent().hide(300);
        $('.draggable-button.move-' + $(this).data('close') + ' .btn').removeClass('active');
    }
});

$('.draggable-button .btn').click(function(){
    var show = $(this).data('show');
    var box = $('.draggable-wrapper.move-'+show);
    $(this).toggleClass('active');
    box.toggle();
});
