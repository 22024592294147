import './bootstrap';
import './general/functions';
import './general/admin-lte';
import './general/datePicker';
import './general/inputmask';
import './general/DataTables';
import './general/select2';
import './general/fullCalendar';

import './includes/client';
//import './includes/events';
import './includes/offer';
import './includes/product';
import './includes/replacement';
import './includes/sidebar';

jQuery(function ($) {
    $('#alert').delay(3000).hide(300);
    $('.treeview-menu li').each(function(){
        if ($(this).hasClass('active'))
            $(this).parent().parent().addClass('active');
    });
});