/** 
 * ----------- ICHECK & CHECKBOXES 
*/

iCheck('input[name="offer[client_wants_assembly]"]');
iCheck('input[name="offer[allows_photography]"]');
iCheck('input[name="offer[panic_lights]"]');

$('input[name="offer[allows_photography]"]').on('click', function() {
    if ($(this).is(':checked') || $(this).val() === 1) $('#offer-photos').show(300);
    else $('#offer-photos').hide(300);
});

if ($('#allows_photography').is(':checked')) {
    $('#offer-photos').show();
}
/**
 * ----------- GENERATORS
 */

var datePickers = 1;
$('#add-contracting-date').on('click', function(){
    var input = document.createElement("INPUT");
    Object.assign(input, {
        className: 'form-control datetimepicker',
        name: 'offer[contracting_dates]['+datePickers+']',
        type: 'text'
    });
    $(input).insertBefore('#contracting_dates').datetimepicker({
        format: "dd.mm.yyyy.H:i",
        buttonText: '',
        language: "hr",
        autoclose: true,
        orientation: 'auto bottom',
        inline: true,
        minDate: "0",
        maxDate: "+1y",
        addSliderAccess: true,
        sliderAccessArgs: { touchonly: false }
    });

    var button = document.createElement("SPAN");
    $(button)
        .attr('data-picker', datePickers)
        .addClass('remove-contracting-date btn btn-default btn-primary')
        .on('click', function(){
            $(input).remove();
            $(this).remove();
        })
        .html(' - ')
        .insertAfter(input);

    datePickers++;
});

var part_index = 0;
$('#add-part').on('click', function() {
    axios.post(route('offer.ajaxPart', {index: part_index}))
        .then(function (response) {
            $('.part-elements').append(response.data);
            $('.drop-select').select2();
            part_index++;
            light_height();
        })
        .catch(function (error) {
            console.log(error);
        });
});

/**
 * ----------- DATEPICKER
 */

 $(".datetimepicker").datetimepicker({
    format: "dd.mm.yyyy.H:i",
    buttonText: '',
    language: "hr",
    autoclose: true,
    orientation: 'auto bottom',
    inline: true,
    minDate: "0",
    maxDate: "+1y",
    addSliderAccess: true,
    sliderAccessArgs: { touchonly: false }
});

$('.old.remove-contracting-date').on('click', function() {
    $(this).prev().remove();
    $(this).remove();
});

/**
 * ----------- FILTERS
 */

$('.client-filter').on('keyup', delay(function(){
    var by = $(this).data('by');
    var filter = $(this).val();
    axios.post(route('client.ajaxFilter', {by: by, filter: filter}))
        .then(function (response) {
            if (response.data) {
                $('#client_id').val(response.data.id);
                $('#client_name').val(response.data.name);
                $('#client_oib').val(response.data.oib);
                $('#client_industry_type').val(response.data.industry_name);
                $('#client_city').val(response.data.city);
                $('#client_address').val(response.data.address);
            }
        })
        .catch(function (error) {
            console.log(error);
        });
}, 500));

$('.industry-filter').on('keyup', delay(function(){
    var filter = $(this).val();
    axios.post(route('industry.ajaxFilter', {filter: filter}))
        .then(function (response) {
            if (response.data.length > 0) {
                selectDropdown($('#client_industry_type'), response.data, 'name');
            }
            else if (typeof response.data === 'object') {
                $('#client_industry_type').val(response.data.name);
                $('#client_industry_type').parent().find('.dropdown-results').hide();
            }
        })
        .catch(function (error) {
            console.log(error);
        });
}, 500));

$('#parts-table').on('change', '.part-filter', delay(function(){
    var row = $(this).parent().parent();
    var data = $('.part-filter-'+$(this).data('row'));
    var filter = [];
    for (let index = 0; index < data.length; index++) {
        const element = data[index];
        filter[element.dataset.filter] = element.value;
    }
    axios.post(route('offer.ajaxFilterPart', filter))
        .then(function (response) {
            if (response.data.id) {
                let element = row.find('.input-product_id');
                element.val(response.data.id);
            }
            else {
                $.each(response.data, function(key, modelName){
                    let element = row.find('.input-'+key);
                    element.empty();
                    element.append('<option value=" -- "> -- </option>');
                    $.each(modelName, function(optionKey, optionValue){
                        var option = new Option(optionValue, optionValue);
                        $(option).html(optionValue);
                        element.append(option);
                    });
                    element.val(filter[key]);
                });
            }
        })
        .catch(function (error) {
            console.log(error);
        });
}, 500));

$('.part-elements').on('change', '.part-filter', function(){
    var box = $(this).closest('.part-box');
    var data = $('.part-filter-'+box.data('index'));
    var filter = [];
    box.children('.overlay').removeClass('hidden');
    for (let index = 0; index < data.length; index++) {
        const element = data[index];
        filter[element.dataset.filter] = element.value;
    }
    axios.post(route('offer.ajaxFilterPart', filter))
        .then(function (response) {
            if (response.data.replacements) {
                console.log(response.data.replacements);
                box.find('.input-product_attribute_id').val(response.data.replacements.product_attribute_id); // new product ID needs to be set properly
                box.find('.product-replacement').val(response.data.replacements.id);
                box.find('.product-replacement').select2().trigger('change');
            }
            $.each(response.data, function(key, modelName){
                let element = box.find('.input-'+key);
                element.empty();
                if (element.is('select')) {
                    element.append('<option value=" -- "> -- </option>');
                    $.each(modelName, function(optionKey, optionValue){
                        var option = new Option(optionValue, optionValue);
                        $(option).html(optionValue);
                    element.append(option);
                    });
                    element.val(filter[key]);
                }
                if (element.is('input')) {
                    element.val(modelName);
                }
            });
            box.children('.overlay').addClass('hidden');
        })
        .catch(function (error) {
            console.log(error);
            box.closest('.overlay').addClass('hidden');
        });
});

$('.part-elements').on('changeSelectData', '.product-filter', function(e, data){
    $(this).siblings('.product-replacement').html(data.name);
    $(this).siblings('.product-replacement-id').val(data.id);
});

$('.part-elements').on('click', '.product-replacement', function(){
    $(this).siblings('.product-filter').click();
});

$('.part-elements').on('click', '.btn-box-tool', function() {
    var widget = $(this).data('widget');
    if (widget === "remove") {
        $(this).closest('.box').hide(3000);
        delay($(this).closest('.box').remove(), 3000);
    }
    if (widget === "collapse") $(this).closest('.box').children('.box-body').slideToggle(300).toggleClass('collapsed-box');
});

$('.part-elements').on('click', '.product-filter', function(){
    var element = $(this);
    axios.get(route('product.index'))
        .then(function (response) {
            selectDropdown(element, response.data);
        })
        .catch(function (error) {
            console.log(error);
        });
});

$('.rate-select').on('change', function() {
    if($(this).val() === "0") $('.rate-count').show();
    else $('.rate-count').hide();
});