/**
 * jQuery Datepicked + timepicker
 */
(function (factory) {
    if (typeof define === "function" && define.amd) {
        // AMD. Register as an anonymous module.
        define(["jquery-ui/ui/widgets/datepicker"], factory);
    } else {
        // Browser globals
        factory(jQuery.datepicker);
    }
}(function (datepicker) {

    datepicker.regional.hr = {
        closeText: "Zatvori",
        prevText: "Prošli",
        nextText: "Idući",
        monthNames: ["Siječanj", "Veljača", "Ožujak", "Travanj", "Svibanj", "Lipanj",
            "Srpanj", "Kolovoz", "Rujan", "Listopad", "Studeni", "Prosinac"
        ],
        monthNamesShort: ["Sij", "Velj", "Ožu", "Tra", "Svi", "Lip",
            "Srp", "Kol", "Ruj", "Lis", "Stu", "Pro"
        ],
        dayNames: ["Nedjelja", "Ponedjeljak", "Utorak", "Srijeda", "Četvrtak", "Petak", "Subota"],
        dayNamesShort: ["Ned", "Pon", "Uto", "Sri", "Čet", "Pet", "Sub"],
        dayNamesMin: ["Ne", "Po", "Ut", "Sr", "Če", "Pe", "Su"],
        weekHeader: "Tje",
        dateFormat: "dd.mm.yy.",
        maxDate: new Date(),
        firstDay: 1,
        isRTL: false,
        showMonthAfterYear: false,
        yearSuffix: ""
    };
    datepicker.setDefaults(datepicker.regional.hr);

    $.timepicker.regional.hr = {
        timeOnlyTitle: 'Vrijeme',
        timeText: 'Sati',
        hourText: 'Sati',
        minuteText: 'Minute',
        secondText: 'Sekunde',
        millisecText: 'Milisekunde',
        timezoneText: 'Vremenska zona',
        currentText: 'Sada',
        closeText: 'Unesi',
        timeFormat: 'HH:mm',
        timeInput: true,
        stepMinute: 10,
        amNames: ['AM', 'A'],
        pmNames: ['PM', 'P'],
        isRTL: false,
        //controlType: 'select',
        oneLine: true,
        language: "hr",
        autoclose: true,
        orientation: 'auto bottom',
        minDate: "0",
        maxDate: "+1y"
    };
    $.timepicker.setDefaults($.timepicker.regional.hr);

    return datepicker.regional.hr;

}));

jQuery(document).ready(function($){
    $(".datetimepicker").datetimepicker();
    $(".datepicker").datepicker({
        format: "dd.mm.yyyy.",
        buttonText: '',
        language: "hr",
        autoclose: true,
        orientation: 'auto bottom',
        //minDate: "-1y"
    });
    $(".datepicker-nolimit").datepicker({
        format: "dd.mm.yyyy.",
        buttonText: '',
        language: "hr",
        autoclose: true,
        orientation: 'auto bottom',
        //minDate: "-1y",
        //maxDate: "+1y"
    });
});
