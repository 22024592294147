/**
 * INPUTMASK
 */
var Inputmask = require('inputmask/dist/inputmask/jquery.inputmask');
require ('inputmask/dist/inputmask/inputmask.extensions');
require ('inputmask/dist/inputmask/inputmask.numeric.extensions');
require ('inputmask/dist/inputmask/inputmask.date.extensions');
// import Inputmask from "inputmask";
// import "inputmask/dist/inputmask/dependencyLibs/inputmask.dependencyLib.jquery.js";


$('.inputmask').inputmask("9-a{1,3}9{1,3}");

$('#client_oib').inputmask("9", {
    repeat: 11,
    greedy: true,
    "oncomplete": function(){ 
        $(this).parent().addClass('has-success').removeClass('has-warning');
    },
    "onincomplete": function(){ 
        $(this).parent().addClass('has-warning').removeClass('has-success');
    },
    "onUnMask": function(maskedValue, unmaskedValue) {
        return maskedValue.replace('_', '');
    }
});

$('#amount_working_days').inputmask({
    alias: "integer",
    suffix: " dana",
    min: 1,
    max: 365,
    removeMaskOnSubmit: true,
    "oncomplete": function(){ 
        $(this).parent().addClass('has-success').removeClass('has-warning');
        if ($(this).inputmask('unmaskedvalue') >= 365) {
            $(this).inputmask("setvalue", 365);
        }
    },
    "onincomplete": function(){ 
        $(this).parent().addClass('has-warning').removeClass('has-success');
    },
});

$('#amount_working_hours').inputmask({
    alias: "integer",
    suffix: " sati",
    min: 1,
    max: 24,
    removeMaskOnSubmit: true,
    "oncomplete": function(){ 
        $(this).parent().addClass('has-success').removeClass('has-warning');
        if ($(this).inputmask('unmaskedvalue') >= 24) {
            $(this).inputmask("setvalue", 24);
        }
    },
    "onincomplete": function(){ 
        $(this).parent().addClass('has-warning').removeClass('has-success');
    },
});

$('.price-mask').inputmask("[9][9][9]9,99 kn", {
    removeMaskOnSubmit: true,
    rightAlign: false,
    numericInput: true,
    "oncomplete": function(){ 
        $(this).parent().addClass('has-success').removeClass('has-warning');
    },
    "onincomplete": function(){ 
        $(this).parent().addClass('has-warning').removeClass('has-success');
    },
    "onUnMask": function(maskedValue, unmaskedValue) {
        return maskedValue.replace(',', '.').replace(' kn', '');
    }
});

$('.percent-mask').inputmask("[9][9][9] %", {
    removeMaskOnSubmit: true,
    rightAlign: false,
    numericInput: true,
    min: 0,
    max: 100,
    "oncomplete": function(){ 
        $(this).parent().addClass('has-success').removeClass('has-warning');
        if ($(this).inputmask('unmaskedvalue') >= 100) {
            $(this).inputmask("setvalue", 100);
        }
    },
    "onincomplete": function(){ 
        $(this).parent().addClass('has-warning').removeClass('has-success');
    },
    "onUnMask": function(maskedValue, unmaskedValue) {
        return maskedValue.replace(',', '.').replace(' %', '');
    }
});

$('.price-mask-short').inputmask("9,99 kn", {
    removeMaskOnSubmit: true,
    rightAlign: false,
    numericInput: true,
    "oncomplete": function(){ 
        $(this).parent().addClass('has-success').removeClass('has-warning');
    },
    "onincomplete": function(){ 
        $(this).parent().addClass('has-warning').removeClass('has-success');
    },
    "onUnMask": function(maskedValue, unmaskedValue) {
        return maskedValue.replace(',', '.').replace(' kn', '');
    }
});



window.light_height = function() {
    $('.input-light_height').inputmask("9,99 m", {
        greedy: false,
        autoUnmask:false,
        suffix: " m",
        removeMaskOnSubmit: true,
        "oncomplete": function(){ 
            $(this).parent().addClass('has-success').removeClass('has-warning');
        },
        "onincomplete": function(){ 
            $(this).parent().addClass('has-warning').removeClass('has-success');
        },
        "onUnMask": function(maskedValue, unmaskedValue) {
            return maskedValue.replace('_', '').replace(',', '.').replace(' m', '');
        }
    });
};