/* eslint-disable max-depth */
/* eslint-disable no-loop-func */
/* eslint-disable no-undef */
$.extend(true, $.fn.dataTable.defaults, {
    "order": [[ 0, "asc" ]], 
    "columnDefs": [ {
        "targets"  : 'no-sort',
        "orderable": false
    }],
    "processing": true,
    "language": {
        "sEmptyTable":      "Nema podataka u tablici",
        "sInfo":            "Prikazano _START_ do _END_ od _TOTAL_ rezultata",
        "sInfoEmpty":       "Prikazano 0 do 0 od 0 rezultata",
        "sInfoFiltered":    "(filtrirano iz _MAX_ ukupnih rezultata)",
        "sInfoPostFix":     "",
        "sInfoThousands":   ",",
        "sLengthMenu":      "Prikaži _MENU_ rezultata po stranici",
        "sLoadingRecords":  "Dohvaćanje...",
        "sProcessing":      "Obrađivanje podataka...",
        "sSearch":          "Pretraži:",
        "sZeroRecords":     "Nema pronađenih rezultata",
        "oPaginate": {
            "sFirst":       "Prva",
            "sPrevious":    "Nazad",
            "sNext":        "Naprijed",
            "sLast":        "Zadnja"
        },
        "oAria": {
            "sSortAscending":  ": aktiviraj za rastući poredak",
            "sSortDescending": ": aktiviraj za padajući poredak"
        }
    }
});

window.DropTableSearch = function(whatTable, num, settings = {}){
    var AddString = "";
    if (num !== 0) AddString = "-"+num;

    var DTsettings = {};

    if (typeof settings.ajax_settings !== "undefined") {
        DTsettings = {
            ajax: settings.ajax_settings,
            columns: [
                //{ data: 'number'},
                { data: 'material'},
                { data: 'block_num'},
                { data: 'first_weigh_datetime'},
                { data: 'second_weigh_datetime'},
                { data: 'raw_net_weight'},
                { data: 'customer_name'},
                { data: 'location_name'},
                { data: 'actions'}
            ],
            // Per-row function to iterate cells
            createdRow: function (row, data, rowIndex) {
                $(row).attr('data-weigh', data.attributes.weigh);
                $(row).attr('data-material', data.attributes.material);
                $(row).attr('data-customer', data.attributes.customer);
                $(row).attr('data-first_weigh_date', data.attributes.first_weigh_date);
                $(row).attr('data-second_weigh_date', data.attributes.second_weigh_date);

                // Per-cell function to do whatever needed with cells
                // $.each($('td', row), function (colIndex) {
                //     // For example, adding data-* attributes to the cell
                //     $(this).attr('data-foo', "bar");
                // });
            },
            //processing: true,
            //serverSide: true,
            info: true,
            paging: true,
            ordering: true,
            drawCallback: function( settings, json){
                $('.weigh-delete').click(function () {
                    var weigh_id = parseInt($(this).data('wid'), 10);
                    var material = $(this).data('material');
                    var table = $(this).closest('table').DataTable({ "retrieve": true});
                    var row = $(this).closest('tr');
                
                    $.post(
                        window.host + "weighs/ajax/" + weigh_id + "/destroy",
                        {
                            _token: token,
                            material: material
                        }
                    ).done(function (response) {
                        table.row(row).remove().draw();
                        $('.ui-tooltip ').remove();
                    });
                });
            }
        };
    }

    if (typeof settings.dom !== "undefined") DTsettings.dom = settings.dom;

    if (typeof settings.buttons !== "undefined") {
        var dbIndex = 0;
        DTsettings.buttons = [{}];

        DTsettings.buttons[0].text = settings.buttons[0].text;
        // $('.'+settings.class+' button.addRow'+AddString).on('click', function (e) {
        //     e.preventDefault();

        //     $('.'+settings.class+' .filter').each(function(e) {
        //         $(this).html('').val('').change();
        //         table.order( [ 0, 'asc' ] ).draw();
        //     });

            //table.context[0].aoData.length + 1
        DTsettings.buttons[0].action = function(){
            var add_array = [];
            for (var i = 0, len = settings.array.length; i < len; i++) {
                add_array.push('');
            }
            add_array.push('<i class="fa fa-times input-delete-row" style="color:red" data-toggle="tooltip" data-placement="top" title="Ukloni red"></i>');
            var rowNode = table.row.add(add_array).draw().node();
            var maxIndex = $(rowNode).children().length;

            $(rowNode).children().each(function(index) {
                var column_index = index + 1;
                var _input = _new = _what = _value = "";

                for (var i = 0, len = settings.array.length; i < len; i++) {
                    if (typeof settings.array[i].input === "undefined") _input = ""; else _input = settings.array[i].input;
                    if (typeof settings.array[i].value === "undefined") _value = ""; else _value = settings.array[i].value;
                    if (typeof settings.array[i].type === "undefined") _type = 'input'; else _type = settings.array[i].type;
                    if (typeof settings.array[i].options_index === "undefined") _options_index = false; else _options_index = settings.array[i].options_index;
                    if (typeof settings.array[i].class === "undefined") _class = ''; else _class = ' '+settings.array[i].class+' '+settings.array[i].class+'-'+dbIndex;
                    if (typeof settings.array[i].data === "undefined") _data = ''; else _data = ' data-'+settings.array[i].data;
                    if (column_index === settings.array[i].index) {
                        $(this).addClass('input-change');
                        switch (_type) {
                            case 'hidden':
                                $(this).prepend('<input type="hidden" class="input-'+_input+_class+'" data-row="'+dbIndex+'" data-filter="'+_input+'" name="new-'+settings.new+'['+dbIndex+']['+_input+']" value="'+_value+'"/>');
                                break;
                            case 'input':
                                $(this).prepend('<input type="text" class="input-'+_input+_class+'" data-row="'+dbIndex+'" data-filter="'+_input+'" name="new-'+settings.new+'['+dbIndex+']['+_input+']" value="'+_value+'"/>');
                                break;
                            case 'select':
                                var select = $('<select class="drop-select input-'+_input+_class+'" data-row="'+dbIndex+'" data-filter="'+_input+'" name="new-'+settings.new+'['+dbIndex+']['+_input+']" value="'+_value+'"></select>');
                                for (var k in settings.array[i].options){
                                    var active = '';
                                    if (typeof settings.array[i].options[k] !== 'function') {
                                        if (_value === settings.array[i].options[k]) active = ' selected';
                                        var value_key = k;
                                        if (_options_index) value_key = settings.array[i].options[k];
                                        select.append( '<option value="'+value_key+'"'+active+'>'+settings.array[i].options[k]+'</option>' );
                                    }
                                }
                                $(this).prepend(select);
                                break;
                            case 'number':
                                $(this).prepend('<input type="number" class="input-'+_input+_class+'" data-row="'+dbIndex+'" data-filter="'+_input+'" name="new-'+settings.new+'['+dbIndex+']['+_input+']" value="'+_value+'"/>');
                                break;
                        }
                    }
                }

                var column = table.columns([1]).every(function(){return this.data();});
                var select = $('<select class="drop-select" ></select>');
                var column_element = unique(column.data()[0].sort());
                $.each(column_element, function ( index, value ) {
                    select.append( '<option value="'+value+'">'+value+'</option>' );
                });

                $(this).on('keyup change', function (e) {
                    if ($(this).children('select').hasClass('drop-select')) {
                        var text = $(this).children('select').val();
                    } else {
                        var text = $(this).html();
                    }
                    var regex = /(<([^>]+)>)/ig;
                    $(this).prev().val(text.replace(regex, ""));
                });
                $('.input-delete-row').on( 'click', function (e) {
                    e.preventDefault();
                    table.row( $(this).parent().parent() ).remove().draw();
                    $('.ui-tooltip ').remove();
                });
            });

            dbIndex++;

            $('.input-change').bind('input propertychange', function(e) {
                e.preventDefault();
                //var theClass = $(this).attr('class').split(' ')[1];
                var text = $(this).html();
                var regex = /(<([^>]+)>)/ig;
                //$('.'+theClass).val(text.replace(regex, ""));
                $(this).prev().val(text.replace(regex, ""));
            });
        };
    }

    DTsettings.responsive = true;
    DTsettings.columnDefs = [{
        targets: 'no-sort',
        orderable: false
    }];

    var table = $(whatTable+AddString).DataTable(DTsettings);

    // Filter settings
    table.columns().every(function () {
        var column = this;
        var filterH = $(column.header()).data('filter');
        var filterF = $(column.footer()).data('filter');

        switch (filterH) {
            case 'search':
                $('<input type="text" class="filter"/>')
                    .appendTo($(column.header()))
                    .on('keyup', function () {
                        var val = $.fn.dataTable.util.escapeRegex($(this).val());
                        column.search( val ? val : '', true, false).draw();
                    });
                break;
            case 'drop':
                var select = $('<select class="filter"><option value=""></option></select>')
                    .appendTo($(column.header()))
                    .on('change', function () {
                        var val = $.fn.dataTable.util.escapeRegex($(this).val());
                        column.search(val ? '^'+val+'$' : '', true, false).draw();
                    });
                column.data().unique().sort().each(function (d, j) {
                    select.append('<option value="'+d+'">'+d+'</option>');
                });
                break;
        }

        switch (filterF) {
            case 'search':
                $('<input type="text" class="filter" />')
                    .appendTo($(column.footer()))
                    .on('keyup change', function () {
                        var val = $.fn.dataTable.util.escapeRegex($(this).val());
                        column.search( val ? '^'+val+'$' : '', true, false).draw();
                    });
                break;
            case 'drop':
                var select = $('<select class="filter"><option value=""></option></select>')
                    .appendTo($(column.footer()))
                    .on('change', function () {
                        var val = $.fn.dataTable.util.escapeRegex($(this).val());
                        column.search( val ? '^'+val+'$' : '', true, false).draw();
                    });
                column.data().unique().sort().each(function (d, j) {
                    select.append('<option value="'+d+'">'+d+'</option>');
                } );
                break;
        }
    });
    // Filter settings END

    $('.filter').on('click', function(e){
        e.stopPropagation();
    });

    return table;
};